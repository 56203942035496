<template>
    <main-section titWrapClass="text-left">
        <template #titHead>C</template>
        <template #tit>ontact Us</template>
        <template #titLeft>
            <v-img src="/images/main/contact-object.svg" :width="$vuetify.breakpoint.mdAndUp ? 650 : '100%'" :aspect-ratio="650 / 80" class="w-100" />
        </template>
        <div>
            <v-row class="row--xl">
                <v-col v-for="(item, index) in contact" :key="index" cols="12" md="6" lg="3" :class="index !== 0 ? 'mt-20px mt-md-0' : ''">
                    <v-card data-aos="fade-up" :data-aos-delay="$vuetify.breakpoint.lgAndUp ? 200*(index+1) : 200" flat tile :to="item.link" class="break-keep v-card--none-active-bg" :class="index % 2 !== 0 ? 'mt-md-60px mt-lg-80px' : ''">
                        <hover-line :lineNone="!item.link">
                            <v-img :src="item.image" :aspect-ratio="300 / 400" class="w-100" />
                            <template #foot>
                                <div class="pt-12px pt-md-24px">
                                    <u-tit-default class="tit--sm font-weight-regular grey--text text--lighten-1 mb-8px mb-md-16px">
                                        <span v-if="item.subTitle">{{item.subTitle}}</span>
                                        <span v-else>0{{index+1}}</span>
                                    </u-tit-default>
                                    <u-tit-default class="tit--sm font-weight-medium line-height-14 font-primary">
                                        {{item.title}}
                                    </u-tit-default>
                                    <u-txt-default v-if="item.txt" class="line-height-17 mt-8px mt-md-16px">
                                        <p v-html="item.txt"></p>
                                    </u-txt-default>
                                </div>
                            </template>
                        </hover-line>                       
                    </v-card>   
                </v-col>
            </v-row>
        </div>
    </main-section>
</template>

<script>
import MainSection from "@/sets/styles/mains/main-section.vue";
import HoverLine from "@/components/publish/styles/hover/hover-line.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    components: {
        MainSection,
        HoverLine,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
            contact: [
                {
                    title : "서울주사무소",
                    txt : "서울시 서초구 서초대로 272, 9층 <br class='d-none d-lg-block' />(한국아이비에스빌딩)판심 법무법인",
                    link: "/contact/seoul",
                    image: "/images/main/contact-img.jpg",
                },
                {
                    title : "수원분사무소",
                    txt : "경기도 수원시 영통구 광교중앙로 248번길 95-1, 7층 (광교법조타운)판심 법무법인",
                    link: "/contact/suwon",
                    image: "/images/main/contact-img2.jpg",
                },
                {
                    title : "인천분사무소",
                    txt : "인천 미추홀구 소성로 171 대흥평창로시스빌딩 로시스동 3층",
                    link: "/contact/incheon",
                    image: "/images/main/contact-img3.jpg",
                },
                {
                    title : "대구분사무소",
                    txt : "대구 수성구 동대구로 345 우방유쉘상가동 2층 206호",
                    link: "/contact/daegu",
                    image: "/images/main/contact-img4.jpg",
                },
            ],
        }
    },
}
</script>

<style lang="scss" scoped>
</style>

<template>
    <u-footer-basic>
        <u-footer-head-basic>
            <template>
                <v-card tile flat 
        style="background-image: url('/images/main/footer.jpg'); background-size: cover; background-position: center;" 
        class="py-20px py-lg-26px d-flex align-center">
    <v-sheet max-width="1440" color="transparent" class="mx-auto w-100 px-20px px-md-30px">
        <v-row align="center" class="mx-md-n20 mx-lg-n40 mx-xl-n110px"> 
            <v-col data-aos="fade-up" data-aos-delay="200" cols="12" lg="auto" class="px-md-20px px-lg-40 px-xl-110px">
                <tit style="font-family: 'Song Myung', serif; color: black;" class="tit tit--xl font-weight-regular line-height-17">
                    당신의 변호사는 누구입니까<br>
                    나의 변호사는 판심입니다
                </tit>
            </v-col>
            <v-col data-aos="fade-up" data-aos-delay="400" class="px-md-20px px-lg-40 px-xl-110px">
                <v-divider style="border-color: rgba(255, 255, 255, .2)"/>
            </v-col>
        </v-row>
    </v-sheet>
</v-card>

            </template>
        </u-footer-head-basic>
        <u-footer-body-basic>
            <template #left>
                <v-img src="/images/symbol.svg" width="60" :aspect-ratio="1 / 1" />
            </template>
            <template #right>
                <footer-info />
                <u-txt-default class="txt--sm txt--light mt-12px mt-md-24px">Copyright 2023. 판심 법무법인 All Rights Reserved.</u-txt-default>
            </template>
        </u-footer-body-basic>
    </u-footer-basic>
</template>

<script>
import LogoSecondary from "@/components/publish/parents/headers/logo-secondary.vue";

import UFooterBasic from "@/sets/styles/footers/u-footer-basic.vue";
import UFooterHeadBasic from "@/sets/styles/footers/u-footer-head-basic.vue";
import UFooterBodyBasic from "@/sets/styles/footers/u-footer-body-basic.vue";

import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import FooterInfo from "@/components/publish/styles/footers/footer-info.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        LogoSecondary,

        UFooterBasic,
        UFooterHeadBasic,
        UFooterBodyBasic,

        UTxtDefault,
        UTxtMedium,
        FooterInfo,

        Tit,
        Txt,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>

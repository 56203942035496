var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-footer-basic', [_c('u-footer-head-basic', [[_c('v-card', {
    staticClass: "py-20px py-lg-26px d-flex align-center",
    staticStyle: {
      "background-image": "url('/images/main/footer.jpg')",
      "background-size": "cover",
      "background-position": "center"
    },
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, [_c('v-sheet', {
    staticClass: "mx-auto w-100 px-20px px-md-30px",
    attrs: {
      "max-width": "1440",
      "color": "transparent"
    }
  }, [_c('v-row', {
    staticClass: "mx-md-n20 mx-lg-n40 mx-xl-n110px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "px-md-20px px-lg-40 px-xl-110px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200",
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('tit', {
    staticClass: "tit tit--xl font-weight-regular line-height-17",
    staticStyle: {
      "font-family": "'Song Myung', serif",
      "color": "black"
    }
  }, [_vm._v(" 당신의 변호사는 누구입니까"), _c('br'), _vm._v(" 나의 변호사는 판심입니다 ")])], 1), _c('v-col', {
    staticClass: "px-md-20px px-lg-40 px-xl-110px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "400"
    }
  }, [_c('v-divider', {
    staticStyle: {
      "border-color": "rgba(255, 255, 255, .2)"
    }
  })], 1)], 1)], 1)], 1)]], 2), _c('u-footer-body-basic', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "src": "/images/symbol.svg",
            "width": "60",
            "aspect-ratio": 1 / 1
          }
        })];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('footer-info'), _c('u-txt-default', {
          staticClass: "txt--sm txt--light mt-12px mt-md-24px"
        }, [_vm._v("Copyright 2023. 판심 법무법인 All Rights Reserved.")])];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
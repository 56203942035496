var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-visual"
  }, [_c('video', {
    staticClass: "main-visual__video",
    attrs: {
      "src": "/images/main/visual.mp4",
      "poster": "/images/main/visual-thumb.jpg",
      "loop": "",
      "autoplay": "",
      "muted": "",
      "playsinline": ""
    },
    domProps: {
      "muted": true
    }
  }), _c('div', {
    staticClass: "main-visual__inner"
  }, [_c('div', {
    staticClass: "main-visual__tit"
  }, [_c('v-img', {
    staticClass: "main-visual__tit__ani w-100",
    attrs: {
      "src": "/images/main/visual-img.svg",
      "max-width": "778",
      "aspect-ratio": 778 / 190
    }
  }), _c('v-img', {
    staticClass: "main-visual__tit__ani w-100 my-10px my-md-26px my-lg-40px",
    attrs: {
      "src": "/images/main/visual-img2.svg",
      "max-width": "778",
      "aspect-ratio": 778 / 138
    }
  }), _c('v-img', {
    staticClass: "main-visual__tit__ani w-100",
    attrs: {
      "src": "/images/main/visual-img3.svg",
      "max-width": "778",
      "aspect-ratio": 778 / 122
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
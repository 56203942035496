<template>
    <div v-if="gnbName == 'sub-tab'" :class="gnbName">
        <template v-for="(item, i) in gnbs">
            <tabs-primary v-if="item.title == sh" :key="i" color="grey darken-4" hide-slider>
                <tab-primary v-for="(child, j) in item.children" :key="j" :to="child.path" :class="{ 'v-tab--active': tabActive == child.title }">{{ child.title }}</tab-primary>
            </tabs-primary>
        </template>
    </div>
    <div v-else-if="gnbName == 'gnb'" :class="gnbName + '-wrap'">
        <ul :class="[gnbName, gnbClass]">
            <template v-for="(item, i) in gnbs">
                <li v-if="!item.children" :key="i" :class="gnbName + '__item'">
                    <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link'">{{ item.title }}</a>
                    <router-link v-else :to="item.path" :class="gnbName + '__link'">
                        <h2>{{ item.title }}</h2>
                    </router-link>
                    <component :is="lnbTag" class="lnb--empty"></component>
                </li>

                <li v-else :key="i" :class="gnbName + '__item'">
                    <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link ' + gnbName + '__link--toggle'">{{ item.title }}</a>
                    <router-link v-else :to="item.path" :class="gnbName + '__link ' + gnbName + '__link--toggle'">
                        <h2>{{ item.title }}</h2>
                    </router-link>

                    <component :is="lnbTag">
                        <li v-for="(child, j) in item.children" :key="j">
                            <a v-if="child.href" :href="child.href" target="_blank">{{ child.title }}</a>
                            <router-link v-else :to="child.path">{{ child.title }}</router-link>
                        </li>
                    </component>
                </li>
            </template>
        </ul>
    </div>
    <div v-else-if="gnbName == 'gnb-mo'" :class="gnbName + '-wrap'">
        <ul :class="[gnbName, gnbClass]">
            <template v-for="(item, i) in gnbs">
                <li v-if="!item.children" :key="i" :class="gnbName + '__item'">
                    <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link'">{{ item.title }}</a>
                    <router-link v-else :to="item.path" :class="gnbName + '__link'">
                        <h2>{{ item.title }}</h2>
                    </router-link>
                </li>

                <li v-else :key="i" :class="gnbName + '__item ' + gnbName + '__item--toggle'">
                    <p :class="gnbName + '__link ' + gnbName + '__link--toggle'">
                        {{ item.title }}
                    </p>
                    <v-btn class="v-btn--text-fit" :class="gnbName + '__arrow'">
                        <icon-arrow-secondary size="x-small" direction="down" />
                    </v-btn>

                    <ul :class="gnbName + '__sub'">
                        <template v-for="(child, j) in item.children">
                            <li v-if="!child.children" :key="j" :class="gnbName + '__sub-item'">
                                <a v-if="child.href" :href="child.href" target="_blank" :class="gnbName + '__sub-link'">{{ child.title }}</a>
                                <router-link v-else :to="child.path" :class="gnbName + '__sub-link'">
                                    {{ child.title }}
                                </router-link>
                            </li>

                            <li v-else :key="j" :class="gnbName + '__sub-item'">
                                <a v-if="child.href" :href="child.href" target="_blank" :class="gnbName + '__sub-link ' + gnbName + '__sub-link--toggle'">{{ child.title }}</a>
                                <router-link v-else :to="child.path" :class="gnbName + '__sub-link ' + gnbName + '__sub-link--toggle'">{{ child.title }}</router-link>

                                <ul :class="gnbName + '__sub'">
                                    <li v-for="grandChild in child.children" :key="grandChild.title" :class="gnbName + '__sub-item'">
                                        <a v-if="grandChild.href" :href="grandChild.href" target="_blank" :class="gnbName + '__sub-link'">{{ grandChild.title }}</a>
                                        <router-link v-else :to="grandChild.path" :class="gnbName + '__sub-link'">{{ grandChild.title }}</router-link>
                                    </li>
                                </ul>
                            </li>
                        </template>
                    </ul>
                </li>
            </template>
        </ul>
    </div>
    <div v-else-if="gnbName == 'sitemap-menu'" :class="gnbName + '-wrap'">
        <ul :class="[gnbName, gnbClass]">
            <template v-for="(item, i) in gnbs">
                <li v-if="!item.children" :key="i" :class="gnbName + '__item'">
                    <span :class="gnbName + '__item-num'">0{{i+1}}</span>
                    <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link'">{{ item.title }}</a>
                    <router-link v-else :to="item.path" :class="gnbName + '__link'">
                        <h2>{{ item.title }}</h2>
                        <p :class="gnbName + '__link__txt'">{{ item.titleKo }}</p>
                    </router-link>
                </li>

                <li v-else :key="i" :class="gnbName + '__item'">
                    <span :class="gnbName + '__item-num'">0{{i+1}}</span>
                    <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link ' + gnbName + '__link--toggle'">
                        {{ item.title }}
                    </a>
                    <router-link v-else :to="item.path" :class="gnbName + '__link ' + gnbName + '__link--toggle'">
                        <h2>{{ item.title }}</h2>
                        <p :class="gnbName + '__link__txt'">{{ item.titleKo }}</p>
                    </router-link>

                    <ul :class="gnbName + '__sub'">
                        <template v-for="(child, j) in item.children">
                            <li v-if="!child.children" :key="j" :class="gnbName + '__sub-item'">
                                <a v-if="child.href" :href="child.href" target="_blank" :class="gnbName + '__sub-link'">{{ child.title }}</a>
                                <router-link v-else :to="child.path" :class="gnbName + '__sub-link'">
                                    {{ child.title }}
                                </router-link>
                            </li>

                            <li v-else :key="j" :class="gnbName + '__sub-item'">
                                <a v-if="child.href" :href="child.href" target="_blank" :class="gnbName + '__sub-link ' + gnbName + '__sub-link--toggle'">{{ child.title }}</a>
                                <router-link v-else :to="child.path" :class="gnbName + '__sub-link ' + gnbName + '__sub-link--toggle'">{{ child.title }}</router-link>
                                <v-btn class="v-btn--text-fit" :class="gnbName + '__arrow'">
                                    <slot name="arrow" />
                                </v-btn>

                                <ul :class="gnbName + '__sub'">
                                    <li v-for="grandChild in child.children" :key="grandChild.title" :class="gnbName + '__sub-item'">
                                        <a v-if="grandChild.href" :href="grandChild.href" target="_blank" :class="gnbName + '__sub-link'">{{ grandChild.title }}</a>
                                        <router-link v-else :to="grandChild.path" :class="gnbName + '__sub-link'">{{ grandChild.title }}</router-link>
                                    </li>
                                </ul>
                            </li>
                        </template>
                    </ul>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import TabsPrimary from "@/components/publish/parents/tabs/tabs-primary.vue";
import TabPrimary from "@/components/publish/parents/tabs/tab-primary.vue";
import Lnb from "@/components/publish/styles/headers/lnb.vue";
import ULnbToplineBorder from "@/components/publish/styles/headers/u-lnb-topline-border.vue";
import ULnbAllFilled from "@/components/publish/styles/headers/u-lnb-all-filled.vue";
import ULnbAllBorder from "@/components/publish/styles/headers/u-lnb-all-border.vue";
import ULnbAllOpacityBlack from "@/components/publish/styles/headers/u-lnb-all-opacity-black.vue";
import IconArrowSecondary from "@/components/publish/parents/icons/icon-arrow-secondary.vue";

export default {
    props: {
        gnbName: String,
        gnbClass: String,
        lnbTag: String,
        sh: String,
        tabActive: String,
    },
    components: {
        TabsPrimary,
        TabPrimary,
        Lnb,
        ULnbToplineBorder,
        ULnbAllFilled,
        ULnbAllBorder,
        ULnbAllOpacityBlack,
        IconArrowSecondary,
    },
    data: function () {
        return {
            gnbs: [
                {
                    title: "판심 법무법인",
                    titleKo: "The firm",
                    path: "/firm/intro",
                    children: [
                        {
                            title: "대표변호사 인사말",
                            path: "/firm/intro",
                        },
                        {
                            title: "판심 법무법인 소개",
                            path: "/firm/about",
                        },
                        {
                            title: "비전",
                            path: "/firm/vision",
                        },
                    ],
                },
                {
                    title: "판심 변호사",
                    titleKo: "Lawyer",
                    path: "/lawyer",
                },
                {
                    title: "자문단",
                    titleKo: "Advisory Committee",
                    path: "/advisory",
                },
                {
                    title: "업무분야",
                    titleKo: "Expertise",
                    path: "/business",
                },
                {
                    title: "언론 & 미디어",
                    titleKo: "Recent Insights",
                    path: "/recent",
                    children: [
                        {
                            title: "언론",
                            path: "/recent",
                        },
                        {
                            title: "미디어",
                            path: "/media",
                        },
                    ],
                },
                {
                    title: "업무사례 & 칼럼",
                    titleKo: "Success Story",
                    path: "/story",
                    children: [
                        {   
                            title: "업무사례",
                            path: "/story?code=story",

                        },
                        {
                            title: "대표변호사 칼럼",
                            path: "/story?code=column",
                        },
                    ],
                },
                {
                    title: "오시는 길",
                    titleKo: "Contact Us",
                    path: "/contact/seoul",
                    children: [
                        {
                            title: "서울주사무소",
                            path: "/contact/seoul",
                        },
                        {
                            title: "수원분사무소",
                            path: "/contact/suwon",
                        },
                        {
                            title: "인천분사무소",
                            path: "/contact/incheon",
                        },
                        {
                            title: "대구분사무소",
                            path: "/contact/daegu",
                        },
                    ],
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
// gnb
.gnb {
    display: none;
}
@media (min-width: 1200px) {
    .gnb {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        width: auto;
        &__item {
            position: relative;
        }
        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            min-height: var(--header-body-height);
            font-size: var(--gnb-font-size);
            font-weight: var(--gnb-font-weight);
            color: var(--v-grey-darken4);
            padding: var(--gnb-padding-y) var(--gnb-padding-x);
            -webkit-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
        }
        h2 {
            font-size: inherit;
        }
    }
}

// gnb-mo
.gnb-mo {
    h2 {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
    }
    &__item {
        position: relative;
        &.open {
            .gnb-mo__sub {
                height: auto;
                opacity: 1;
                visibility: visible;
                padding: 8px 0;
            }
        }
    }
    &__link {
        position: relative;
        display: flex;
        align-items: center;
        height: 54px;
        font-size: 1.4rem;
        padding: 0 var(--container-gutter);
    }
    &__link--toggle {
        padding-right: calc(var(--container-gutter) + 30px);
    }
    &__arrow {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        width: calc(var(--container-gutter) + 30px) !important;
        height: 54px !important;
        border-radius: 0 !important;
    }
    &__sub {
        height: 0;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        transition: all ease-out 0.2s;
        .gnb-mo__sub {
            padding: 0 0 0 var(--container-gutter) !important;
        }
    }
    &__sub-link {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        padding: 6px var(--container-gutter);
    }
}

// sitemap-menu
.sitemap-menu {
    h2 {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
    }
    &__sub-item {
        .sitemap-menu__sub {
            height: 0;
            margin: 0;
            overflow: hidden;
            opacity: 0;
            visibility: hidden;
            transition: all ease-out 0.2s;
        }

        &.open {
            .sitemap-menu {
                &__sub {
                    height: auto;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
</style>

<template>
    <div class="main-visual">
	<video src="/images/main/visual.mp4" poster="/images/main/visual-thumb.jpg" loop autoplay muted playsinline class="main-visual__video"/>
        <div class="main-visual__inner">
            <div class="main-visual__tit">
                <v-img src="/images/main/visual-img.svg" max-width="778" :aspect-ratio="778 / 190" class="main-visual__tit__ani w-100" />
                <v-img src="/images/main/visual-img2.svg" max-width="778" :aspect-ratio="778 / 138" class="main-visual__tit__ani w-100 my-10px my-md-26px my-lg-40px" />
                <v-img src="/images/main/visual-img3.svg" max-width="778" :aspect-ratio="778 / 122" class="main-visual__tit__ani w-100" />
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {
    },
    props: {},
    data() {
        return {
        };
    },
};
</script>

<style lang="scss" scoped>
.main-visual{
    width: 100%;
    height: 0;
    padding-top: 170%;
    overflow: hidden;
    position: relative;
    &__video{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
        display: block;
        overflow: hidden; 
        &[poster]{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &::after{
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        background-image: url(/images/main/visual-bg.jpg);
        background-position: right center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: .75;
    }
    &__inner{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    &__tit{
        width: 100%;
        max-width: 220px;
        &__ani{
            transform: scale(1.4);
            opacity: 0;
            visibility: hidden;
            animation: zoomOut 1.3s ease-out forwards;
            &:nth-child(3){
                animation-delay: .2s;
                clear: both;
            }
            &:nth-child(1){
                animation-delay: .4s;
            }
            &:nth-child(2){
                animation-delay: .6s;
            }
        }
    }
}
@media (min-width:576px){
    .main-visual{
        &__tit{
            max-width: 340px;
        }
    }
}
@media (min-width:768px){
    .main-visual{
        &__tit{
            max-width: 500px;
        }
    }
}
@media (min-width:1024px){
    .main-visual{
        height: 100vh;
        padding-top: 0;
        &__tit{
            max-width: 778px;
        }
    }
}
@media (min-width:1200px){
}
@media (min-width:1660px){
    .main-visual{
        &__inner{
            padding-right: 80px;
        }
    }
}
@keyframes zoomOut {
    0%{
        transform: scale(1.4);
        opacity: 0;
        visibility: hidden;
    }
    100%{        
        transform: scale(1);
        opacity: 1;
        visibility: visible;
    }
}
</style>

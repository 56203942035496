<template>
    <main-section class="py-0">
        <template #mainSectionHead>

            <!-- S: ceo -->
            <div class="lawyer lawyer--top">
                <v-row no-gutters class="lawyer__left">
                    <v-col cols="12" md="6">
                        <v-responsive :aspect-ratio="960 / 1056" :min-height="$vuetify.breakpoint.mdAndUp ? 900 : ''" class="w-100 h-100">
                            <div class="lawyer__img" :style="'background-image: url('+repLawyer.mainImage+')'"></div>
                        </v-responsive>
                    </v-col>
                    <v-col cols="12" md="6" class="d-none d-md-block">
                        <v-img src="/images/main/lawyer-bg.jpg" :aspect-ratio="960 / 1056" :min-height="$vuetify.breakpoint.mdAndUp ? 900 : ''" class="w-100 h-100" />
                    </v-col>
                </v-row>
                <v-container class="lawyer__right">
                    <div class="py-40px py-md-0">
                        <v-row no-gutters>
                            <v-col cols="12" md="6" offset-md="6">
                                <tit-wrap-primary dark class="lawyer__title pb-0">
                                    <template #TitHead>L</template>awyer
                                </tit-wrap-primary>
                                <div class="lawyer__contents-wrap">
                                    <v-card data-aos="fade-up" tile outlined color="white" class="mb-20px mb-md-40px" style="width: fit-content;">
                                        <div class="px-12px">
                                            <u-tit-default class="tit--xl line-height-12 white--text font-weight-regular" style="font-family: 'Song Myung', serif;">
                                                <span class="primary--text text--lighten-4">판사 출신 </span>대표 변호사
                                            </u-tit-default>
                                        </div>
                                    </v-card>
                                    <v-card :to="`/lawyer/${repLawyer._id}`" tile flat color="transparent" class="lawyer__contents v-card--none-active-bg plus-btn-card">
                                        <u-tit-default data-aos="fade-up" data-aos-delay="200" class="tit--lg font-weight-bold white--text line-height-14 pb-8px pb-md-16px">
                                            {{ repLawyer.name }}
                                        </u-tit-default>
                                        <v-row data-aos="fade-up" data-aos-delay="400" class="lawyer-info my-n2px my-md-n6px mx-md-n12px mx-lg-n30px">
                                            <v-col v-for="(child, childIndex) in repLawyer.career" :key="childIndex" cols="12" md="auto" class="py-2px py-md-6px px-md-12px px-lg-30px">
                                                <u-txt-dot class="white--text line-height-14"><span v-html="child.txt"></span></u-txt-dot>
                                            </v-col>
                                        </v-row>
                                        <div data-aos="fade-up" data-aos-delay="400" class="mt-40px mt-md-60px mt-lg-80px">
                                            <icon-plus class="white--text"/>
                                        </div>                                        
                                    </v-card>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </div>
            <!-- E: ceo -->

            <!-- S: lawyer -->
            <div class="lawyer lawyer--bottom overflow-hidden grey lighten-5">
                <v-row no-gutters class="lawyer__left">
                    <v-col cols="12" md="6" class="d-none d-md-block">
                        <v-img src="/images/main/lawyer-bg2.jpg" :aspect-ratio="960 / 576" :min-height="$vuetify.breakpoint.mdAndUp ? 576 : ''" class="w-100 h-100" />
                    </v-col>
                    <v-col data-aos="fade-up" data-aos-delay="200" cols="12" md="6">
                        <swiper class="swiper swiper--left" ref="swiperLeft" :options="swiperOptionsLeft">
                            <template v-for="(item, key) in listLawyers">
                                <swiper-slide :key="key" v-if="key  !== 'lawyer01'">
                                    <v-responsive :aspect-ratio="960 / 576" :min-height="$vuetify.breakpoint.mdAndUp ? 576 : ''" class="w-100 h-100">
                                        <div class="lawyer__img white" :style="'background-image: url('+item.mainImage+')'"></div>
                                    </v-responsive>
                                </swiper-slide>
                            </template>
                        </swiper>
                    </v-col>
                </v-row>
                <v-container class="lawyer__right">
                    <div class="py-40px py-md-0">
                        <v-row no-gutters>
                            <v-col cols="12" md="6">
                                <div data-aos="fade-up" class="lawyer__contents-wrap">
                                    <swiper class="swiper swiper--right" ref="swiperRight" :options="swiperOptionsRight">
                                        <template v-for="(item, key) in listLawyers">
                                            <swiper-slide :key="key" v-if="key  !== 'lawyer01'">
                                                <v-card style="cursor: pointer;" :to="item.type === MEMBER_TYPES.LAWYER.value ? `/lawyer/${item._id}` : `/advisory/${item._id}`" tile flat color="transparent" class="lawyer__contents v-card--none-active-bg plus-btn-card">
                                                    <div class="pb-8px pb-md-16px">
                                                        <v-row align="center">
                                                            <v-col cols="auto">
                                                                <u-tit-default class="tit--lg font-weight-bold line-height-14">
                                                                    {{item.name}}
                                                                </u-tit-default>
                                                            </v-col>
                                                            <v-col cols="auto">
                                                                <v-divider class="grey lighten-1 w-40px w-md-80px"/>
                                                            </v-col>
                                                            <v-col cols="auto">
                                                                <u-txt-medium class="txt--lg txt--dark line-height-14">
                                                                    <span v-if="item.job">{{item.job}}</span>
                                                                    <span v-else>변호사</span>
                                                                </u-txt-medium>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                    <v-row class="lawyer-info my-n2px my-md-n6px mx-md-n12px mx-lg-n30px">
                                                        <v-col v-for="(child, childIndex) in item.career" :key="childIndex" cols="12" md="auto" class="py-2px py-md-6px px-md-12px px-lg-30px">
                                                            <u-txt-dot class="line-height-14"><span v-html="child.txt"></span></u-txt-dot>
                                                        </v-col>
                                                    </v-row>
                                                    <icon-plus class="mt-40px mt-md-60px mt-lg-80px"/>
                                                </v-card>
                                            </swiper-slide>
                                        </template>
                                    </swiper>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>                
                <div class="lawyer--bottom__btn-group">
                    <v-row no-gutters>
                        <v-col cols="6" md="auto">
                            <v-btn color="primary lighten-4" block dark fab tile class="lawyer--bottom__btn lawyer--bottom__btn--prev">
                                <icon-arrow-primary />
                            </v-btn>
                        </v-col>
                        <v-col cols="6" md="auto">
                            <v-btn color="primary lighten-3" block dark fab tile class="lawyer--bottom__btn lawyer--bottom__btn--next">
                                <icon-arrow-primary direction="right" />
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <!-- E: lawyer -->

        </template>
    </main-section>
</template>

<script>
import api from "@/api"
import { MEMBER_TYPES } from "@/assets/variables";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { lawyer } from "@/assets/data/lawyer.js";
import MainSection from "@/sets/styles/mains/main-section.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTxtDot from "@/components/publish/styles/typography/u-txt-dot.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        MainSection,
        IconArrowPrimary,
        IconPlus,
        TitWrapPrimary,
        UTxtDefault,
        UTxtMedium,
        UTxtDot,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
            MEMBER_TYPES,
            lawyer,
            // swiperOptionsLeft: {
            //     effect: "fade",
            //     loop: false,
            //     slidesPerView: 1,
            //     touchRatio: 0,
            //     speed: 500,
            //     lazy: {
            //       loadPrevNext: true,
            //       loadPrevNextAmount: 10
            //     },
            //     loopedSlides: 10,
            // },
            // swiperOptionsRight: {
            //     loop: false,
            //     slidesPerView: 1,
            //     speed: 500,
            //     spaceBetween: 24,
            //     autoHeight : true,
            //     lazy: {
            //       loadPrevNext: true,
            //       loadPrevNextAmount: 10
            //     },
            //     loopedSlides: 10,
            //     autoplay: {
            //         delay: 7000,
            //         disableOnInteraction: false,
            //     },
            //     navigation: {
            //         nextEl: ".lawyer--bottom__btn--next",
            //         prevEl: ".lawyer--bottom__btn--prev",
            //     },
            // },
            summary: { totalCount: 0 },
            repLawyer: {},
            listLawyers: []
        }
    },
    computed: {
        swiperOptionsLeft() {
            return {
                effect: "fade",
                loop: false,
                slidesPerView: 1,
                touchRatio: 0,
                speed: 500,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 10,
                },
                loopedSlides: this.listLawyers.length - 1,
            };
        },
        swiperOptionsRight() {
            return {
                loop: false,
                slidesPerView: 1,
                speed: 500,
                spaceBetween: 24,
                autoHeight: true,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 10,
                },
                loopedSlides: this.listLawyers.length - 1,
                autoplay: {
                    delay: 70000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: ".lawyer--bottom__btn--next",
                    prevEl: ".lawyer--bottom__btn--prev",
                },
            };
        },
    },
    mounted() {
        this.search();
        this.$refs.swiperLeft.$swiper.controller.control = this.$refs.swiperRight.$swiper;
        this.$refs.swiperRight.$swiper.controller.control = this.$refs.swiperLeft.$swiper;
    },
    methods:{
        async search(){
            try{
                let { summary, lawyers } = await api.v1.lawyers.gets();
                
                // 경력 메인 노출만
                lawyers = lawyers.map( lawyer => {
                    return {
                        ...lawyer,
                        career: lawyer.career.filter( career => career.isMain === true),
                    }
                })
                //대표 변호사
                this.repLawyer = lawyers.find( lawyer => lawyer.rank === 0);

                //리스트 변호사
                this.listLawyers = lawyers.filter( lawyer => lawyer.rank !== 0);

                this.summary = summary;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.lawyer{
    &__right{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__img{
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &--top{
        .lawyer__right{
            background-image: url(/images/main/lawyer-bg.jpg);
        }
        .lawyer__contents-wrap{
            padding: 40px 0 0 0;
        }
    }
    &--bottom{
        .lawyer__right{
            background-image: url(/images/main/lawyer-bg2.jpg);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .lawyer{
        position: relative;
        &__right{
            background-image: none !important;
            position: absolute !important;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 5;
        }
        &__title{
            transform: translateX(-50%);
            width: fit-content;
            white-space: nowrap;
        }
        &--top{
            .lawyer__contents-wrap{
                padding: 80px 0 0 30px;
            }
        }
        &--bottom{
            .lawyer__contents-wrap{
                padding-right: 30px;
            }
            &__btn-group{
                z-index: 10;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
            &__btn{
                --btn-height: 80px
            }
        }
    }
    .lawyer-info{
        overflow: hidden;
        max-height: 186px;
        flex-direction: column;
    }
}
@media (min-width:1024px){
    .lawyer{
        &--top{
            .lawyer__contents-wrap{
                padding: 120px 0 0 30px;
            }
        }
        &--bottom{
            &__btn{
                --btn-height: 120px
            }
        }
    }
}
@media (min-width:1200px){
    .lawyer{
        &--top{
            .lawyer__contents-wrap{
                padding-left: 134px;
            }
        }
        &--bottom{
            .lawyer__contents-wrap{
                padding-right: 134px;
            }
        }
    }
}

</style>

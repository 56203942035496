<template>
    <main-section class="pb-0">
        <template #titHead>O</template>
        <template #tit>ur Business</template>
        <template #mainSectionFoot>
            <v-row no-gutters>
                <v-col v-for="item in business" :key="item.title" cols="12" sm="6" md="4">
                    <v-card :href="item.link" target="_blank" tile flat dark class="v-card--none-active-bg plus-btn-card h-100">
                        <hover-line :lineNone="!item.link">
                            <v-img :src="item.image" :aspect-ratio="640 / 300" class="w-100 h-100">
                                <div class="h-100 pa-16px pa-md-24px pa-lg-36px card-inner">
                                    <div>
                                        <u-tit-default data-aos="fade-up" class="tit--sm line-height-14 font-weight-bold">{{ item.title }}</u-tit-default>
                                        <u-tit-default data-aos="fade-up" data-aos-delay="200" class="tit--sm mt-8px mt-md-16px">{{ item.subTitle }}</u-tit-default>
                                    </div>
                                    <div v-if="item.link" class="icon-plus-wrap">
                                        <div data-aos="fade-up" data-aos-delay="400">
                                            <icon-plus />
                                        </div>
                                    </div>
                                </div>
                            </v-img>
                        </hover-line>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </main-section>
</template>

<script>
import MainSection from "@/sets/styles/mains/main-section.vue";
import HoverLine from "@/components/publish/styles/hover/hover-line.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    components: {
        MainSection,
        HoverLine,
        IconPlus,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
            business: [
                {
                    title: "성범죄",
                    subTitle: "Sexual assault",
                    link: "https://judgemind.net/",
                    image: "/images/main/business-img.jpg",
                },
                {
                    title: "교통",
                    subTitle: "Traffic",
                    link: "https://speed.judgemind.co.kr/",
                    image: "/images/main/business-img2.jpg",
                },
                {
                    title: "이혼",
                    subTitle: "Divorce",
                    link: "https://judgemind.kr/",
                    image: "/images/main/business-img3.jpg",
                },
                {
                    title: "스토킹",
                    subTitle: "Stalking",
                    link: "https://www.judgemind.com/",
                    image: "/images/main/business-img4.jpg",
                },
                {
                    title: "군형사",
                    subTitle: "Army",
                    link: "https://army.judgemind.co.kr/",
                    image: "/images/main/business-img5.jpg",
                },
                {
                    title: "마약",
                    subTitle: "Drugs",
                    link: "https://drug.judgemind.co.kr/",
                    image: "/images/main/business-img6.jpg",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.card-inner {
    position: relative;
}
.icon-plus-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
